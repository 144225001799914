import { createRouter, createWebHistory } from 'vue-router';
import SurveyPage from '../views/SurveyPage.vue';
import SurveyPageSingleDelivery from '..//views/SurveyPageSingleDelivery';
import SurveyPageNotPreSelected from '../views/SurveyPageNotPreSelected.vue';
import Header from '../views/Header.vue';
import ThankYou from '../views/ThankYou.vue';

const routes = [
  {
    path: "/",
    name: "Header",
    component: Header,
    children: [
      {
        path: "/pesquisa-satisfacao",
        name: "SurveyPage",
        component: SurveyPage,
      },
      {
        path: "/entregaveis-separados",
        name: "SurveyPageSingleDelivery",
        component: SurveyPageSingleDelivery,
      },
      {
        path: "/pesquisa-satisfacao-link",
        name: "SurveyPageNotPreSelected",
        component: SurveyPageNotPreSelected,
      },
      {
        path: "/obrigado",
        name: "thank-you",
        component: ThankYou,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
