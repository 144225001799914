import axios from 'axios';

export default class SurveyService {
  static answerSurvey(survey) {
    return axios.post("/api/v1/survey", survey);
  }

  static answerSurveySingleDelivery(surveySingleDelivery) {
    return axios.post("/api/v1/survey/single-delivery", surveySingleDelivery);
  }

  static putObservation(data) {
    return axios.patch('/api/v1/survey/observation', data);
  }
}
