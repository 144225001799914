<template>
  <div class="thank-you">
    <h1>
      Obrigado!
    </h1>
    <h3>Sua avaliação é muito importante para nós!</h3>
  </div>
</template>
<style scoped>
.thank-you {
  margin-top: 100px;
}
</style>
