<template>
  <div class="survey-boxes">
    <div
      :class="{'box': true, 'red': true, 'box--selected': value === 1, 'some-selected': value !== null}"
      @click="input(1)"
    >
      😡
    </div>
    <div
      :class="{'box': true, 'orange': true, 'box--selected': value === 2, 'some-selected': value !== null}"
      @click="input(2)"
    >
      😔
    </div>
    <div
      :class="{'box': true, 'yellow': true, 'box--selected': value === 3, 'some-selected': value !== null}"
      @click="input(3)"
    >
      😐
    </div>
    <div
      :class="{'box': true, 'light-green': true, 'box--selected': value === 4, 'some-selected': value !== null}"
      @click="input(4)"
    >
      🙂
    </div>
    <div
      :class="{'box': true, 'green': true, 'box--selected': value === 5, 'some-selected': value !== null}"
      @click="input(5)"
    >
      😀
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
  },
  methods: {
    input(value) {
      this.$emit('input', value);
    },
  },
}
</script>
<style scoped>
.survey-boxes {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 31px;
  height: 31px;
  margin-right: 3px;
  margin-left: 3px;
  background: #EAEAEA;
  cursor: pointer;
  transition: transform .1s ease-in-out, background-color .5s ease-in-out;
}
.box--selected {
  transform: scale(1.2);
}
.red:not(.some-selected), .red.box--selected {
  background-color: #FF0000;
}
.orange:not(.some-selected), .orange.box--selected {
  background-color: #F36F21;
}
.yellow:not(.some-selected), .yellow.box--selected {
  background-color: #FFCA27;
}
.light-green:not(.some-selected), .light-green.box--selected {
  background-color: #C5D92D;
}
.green:not(.some-selected), .green.box--selected {
  background-color: #64B64D;
}
</style>
