<template>
  <div class="flex-column">
    <div class="container">
      <h4 class="title">Obrigado pela sua avaliação!!!</h4>
      <div class="survey-feedback">
        <survey-box v-bind:icon="'😡'" v-bind:isSelected="this.nota === '1'" />
        <survey-box v-bind:icon="'😔'" v-bind:isSelected="this.nota === '2'" />
        <survey-box v-bind:icon="'😐'" v-bind:isSelected="this.nota === '3'" />
        <survey-box v-bind:icon="'🙂'" v-bind:isSelected="this.nota === '4'" />
        <survey-box v-bind:icon="'😀'" v-bind:isSelected="this.nota === '5'" />
      </div>
      <div>
        <p class="subtitles">
          Sua avaliação foi registrada com sucesso!
        </p>
        <p>
          Gostaria de complementar com um comentário, elogio ou sugestão de
          melhoria?
        </p>
        <br />
        <span class="subtitles">Seu comentário</span>
      </div>
      <div class="flex-column">
        <textarea
          class="custom-input"
          v-model="comment"
          placeholder="Deixe seu comentário..."
          rows="7"
        />
        <button class="custom-button" v-on:click="sendComment">
          ADICIONAR COMENTÁRIO
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import SurveyBox from '../components/SurveyBox.vue';
import SurveyService from '../domain/service/SurveyService';

export default {
  components: {
    SurveyBox,
  },
  data() {
    return {
      nota: this.$route.query.grade,
      comment: '',
      deliverableIDs: [],
      surveyIds: [],
    };
  },
  mounted() {
    this.deliverableIDs = this.$route.query.deliverableIds.split(',');
    SurveyService.answerSurvey({
      deliverableIds: this.deliverableIDs,
      clientName: this.$route.query.clientName,
      clientEmail: this.$route.query.clientEmail,
      grade: this.$route.query.grade,
    }).then(({ data }) => {
      this.surveyIds = data;
    });
  },
  methods: {
    sendComment() {
      SurveyService.putObservation({
        observation: this.comment,
        surveyIds: this.surveyIds,
      })
      .then(() => {
        createToast("Comentário enviado com sucesso!", {
          type: "success",
        });
      })
      .catch(() => {
        createToast(
          "Tivemos um erro ao enviar seu comentário, tente novamente mais tarde!",
          {
            type: "danger",
          }
        );
      });
    },
  },
};
</script>

<style>
.container {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.title {
  font-weight: 600;
  font-size: 24px;
  color: #2b2b2e;
  margin-top: 40px;
}
.subtitles {
  color: #2b2b2e;
  font-weight: bold;
  font-size: 15px;
  margin-top: 40px;
  margin-bottom: 8px;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.survey-feedback {
  display: flex;
  align-items: center;
}
.custom-input {
  background: #f3f3f3;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-top: 8px;
  padding: 16px;
}
.custom-button {
  padding: 8px;
  border: none;
  background: #41172b;
  color: white;
  border-radius: 2px;
  margin-top: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.custom-button:hover {
  cursor: pointer;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
</style>
