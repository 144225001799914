<template>
    <div class="flex-column">
      <div class="survey">
        <h2>Olá!</h2>
        <p>Como você avalia as seguintes entregas:</p>
        <div v-for="(survey, i) in surveys" :key="i">
          <p></p>
          <p v-html="survey.deliveryName"/>
          <survey-boxes
              class="survey__boxes" 
              :value="survey.grade"
              @input="updateGrade($event, i)"
          />

          <textarea
            class="custom-input"
            v-model="survey.observation"
            placeholder="Deixe seu comentário..."
            rows="4"
          />
        </div>
        <div class="align-left">
          <input v-model="sendAsAnonymous" type="checkbox"/>
          <label class="send-as-anonymous-label">Enviar como anônimo</label>
        </div>
        <div class="mt-3 grid-container" v-show="!sendAsAnonymous">
          <label class="align-left comment-label">Nome</label>
          <input v-model="clientName" class="input pa-3"/>
          <label class="mt-3 align-left comment-label">E-mail</label>
          <input v-model="clientEmail" class="input pa-3"/>
        </div>
        <div class="mt-3 btn-container">
          <button 
            :class="{'btn': true, 'btn--disabled': !hasAllGrades()}"
            :disabled="!hasAllGrades()"
            @click="send"
            >
            Enviar
          </button>
        </div>
      </div>
    </div>
</template>
<script>
import SurveyBoxes from '../components/SurveyBoxesToSelect.vue';
import { createToast } from 'mosha-vue-toastify';
import 'mosha-vue-toastify/dist/style.css';
import SurveyService from '../domain/service/SurveyService';
import SeparateSurvey from '../domain/entity/SeparateSurvey.entity';
import SurveySingleDelivery from '../domain/entity/SurveySingleDelivery.entity';

export default {
  components: {
    SurveyBoxes,
  },
  data: () => ({
    sendAsAnonymous: true,
    clientName: '',
    clientEmail: '',
    surveys: [],
  }),
  methods: {
    updateGrade(grade, index) {
      this.surveys[index].grade = grade;
    },

    hasAllGrades() {
      return !this.surveys.some(survey => survey.grade === null)
    },
    send() {
      if (this.sendAsAnonymous) {
        this.clientName = null
        this.clientEmail = null
      }
      const surveySingleDelivery = new SurveySingleDelivery({ surveys:this.surveys, clientName: this.clientName, clientEmail: this.clientEmail })
      SurveyService.answerSurveySingleDelivery(surveySingleDelivery)
        .then(() => {
          createToast("Avaliação enviada com sucesso!", { type: "success" });
          this.$router.push({ name: 'thank-you' });
        }).catch(() => {
          const errorMessage = 'Tivemos um erro ao enviar sua avaliação, tente novamente mais tarde!';
          createToast(errorMessage, { type: 'danger' });
        });
    },
  },
  created() {
    const deliverableIds = this.$route.query.deliverableIds.split(',');
    const deliveryNames = this.$route.query.deliverableName.split('<br/>');
    this.clientName = this.$route.query.clientName
    this.clientEmail = this.$route.query.clientEmail

    this.surveys = deliverableIds.map((id, i) =>
        new SeparateSurvey({deliverableId: id, deliveryName: deliveryNames[i]})
    )
  },
}
</script>
<style scoped>
.survey {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
}
.btn {
  padding: 8px;
  border: none;
  background-color: #41172b;
  color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 40px;
  width: 100px;
  cursor: pointer;
}
.btn.btn--disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  box-shadow: none;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.survey__boxes {
  margin-bottom: 20px;
}
.custom-input {
  background: #f3f3f3;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 20px;
  padding: 16px;
}
.input {
  background: #f3f3f3;
  border: 1px solid #bbbbbb;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  margin-top: 2px;
  
}
.align-left {
  display: flex;
  justify-content: flex-start;
}
.send-as-anonymous-label {
  font-size: 14px;
  margin-top: 2px;
}
.comment-label {
  font-size: 14px;
  font-weight: bold;
}
.grid-container {
  width: 90%;
}
.mt-3 {
  margin-top: 12px;
}
.pa-4 {
  padding: 16px;
}
.pa-3 {
  padding: 12px;
}
</style>
