<template>
  <div>
    <div class="header">
      <img src="../assets/logo.png" alt=""/>
    </div>
    <router-view/>
  </div>
</template>
<style>
.header {
  height: 80px;
  background: #3f0c29;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
</style>
