<template>
  <div v-bind:class="[isSelected && 'selected-box', 'survey-box']">
    <span v-bind:class="[isSelected && 'grow-icon']">
      {{ this.icon }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    icon: { required: true, type: String },
    isSelected: { required: true, type: Boolean },
  },
  name: "SurveyBox",
};
</script>

<style>
.survey-box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 31px;
  height: 31px;
  margin-right: 2.5px;
  margin-left: 2.5px;
  background: #eaeaea;
}
.selected-box {
  background: #64b64d;
  width: 47px;
  height: 47px;
}
.grow-icon {
  transform: scale(1.5);
}
</style>
